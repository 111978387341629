/* You can add global styles to this file, and also import other style files */
.clickable {
  cursor: pointer;
  text-decoration: underline;
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
}

.spinner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.p-icon.p-sortable-column-icon {
  color: #107585;
}

.btn-primary {
  font-weight: bold;
  &.btn:active {
    background-color: #107585;
    border-color: #107585;
  }
  &:hover {
    background-color: #107585;
    color: white;
    border-color: #d1d5db;
  }
  &:disabled {
    background-color: #107585;
    color: white;
    cursor: not-allowed;
  }

  background-color: #107585;
  font-family: "Open Sans";
}

.table-commission.p-datatable {
  font-size: 13px;
  .p-datatable-thead > tr {
    border-width: 2px;
    border-color: #107585;
    th {
      color: #107585;
    }
  }

  .p-datatable-tbody > tr {
    border-width: 2px;
    border-color: #e1e1e1;
    td {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}

p-columnfilter {
  input {
    border-radius: 0;
    height: 2rem;
    font-size: 0.8rem;
    box-shadow: none;
  }
  button {
    display: none;
  }
}

.icon-green {
  color: green;
  font-weight: 600 !important;
  font-size: 1.1rem;
}

.icon-red {
  color: red;
  font-weight: 600 !important;
  font-size: 1.1rem;
}

.icon {
  color: #107585;
  font-weight: 600 !important;
  font-size: 1.1rem;
  cursor: pointer;
}

.commission-form {
  font-weight: 700;
  color: #107585;
  border-color: #9a9fa8;
  font-family: "Open Sans";
}

.dropdown.p-dropdown {
  border-radius: 0;
  width: 100%;
  box-shadow: none;

  .p-dropdown-panel {
    border-radius: 0;
  }
  .p-dropdown-label {
    padding: 0.375rem 0.75rem;
  }
  .p-dropdown-items {
    padding: 0;
  }
  &:hover {
    border-color: #d1d5db;
  }
  &:active {
    border-color: #d1d5db;
  }
}

.autocomplete-dropdown {
  width: 100%;
  box-shadow: none;
  border-radius: 0;
  padding-top: 0.2rem;

  input {
    border-radius: 0;
    padding: 0.375rem 0.75rem;
    &:focus {
      border-color: #d1d5db;
    }
    &:hover {
      border-color: #d1d5db;
    }
    box-shadow: none;
  }
  button {
    background-color: #107585;
    padding: 0;
    height: 38px;
  }

  .p-autocomplete-items {
    padding: 0;
  }
  .p-autocomplete-panel {
    border-radius: 0;
  }
}

.p-inputswitch {
  margin-top: 0.7rem;
  margin-bottom: 0.5rem;

  &-checked {
    .p-inputswitch-slider {
      background: #107585;
    }
  }
}

.no-spinner::-webkit-outer-spin-button,
.no-spinner::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-spinner {
  -moz-appearance: textfield; /* Firefox */
}

.calendar {
  width: 100%;

  .p-inputtext {
    padding: 0.375rem 0.75rem;
    border-radius: 0;
    box-shadow: none;
  }
  &:hover {
    border-color: #d1d5db;
  }
  button {
    background-color: #107585;
    padding: 0;
  }
  .p-datepicker {
    border-radius: 0;
    button {
      background-color: transparent;
    }
  }
}

.text {
  font-weight: 700;
  color: #107585;
  border-color: #9a9fa8;
  font-family: "Open Sans";
}
